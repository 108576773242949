  <div class="sundo-container medium m-auto h-100 position-relative">
    <ng-container *ngIf="currentStep === 'casing'">
      <app-h2 class="text-center">Scan de casing</app-h2>
      <app-js-qr-scanner
        [badScan]="badScan"
        [processingScan]="processingScan"
        (codeScanned)="handleCasingScan($event)"
      ></app-js-qr-scanner>
      <app-warning-button [fullWidth]="true" (click)="router.navigate(['/production/create/custom-casing'])">Maak een nieuwe casing aan</app-warning-button>
    </ng-container>
    <ng-container *ngIf="currentStep === 'backplate'">
      <app-h2 class="text-center">Scan de backplate</app-h2>
      <app-js-qr-scanner
        [badScan]="badScan"
        [processingScan]="processingScan"
        (codeScanned)="handleBackplateScan($event)"
      ></app-js-qr-scanner>
    </ng-container>
    <ng-container *ngIf="currentStep === 'checkInfoPanel'">
      <app-h2 class="text-center">Wens je een info bord toe te voegen?</app-h2>
      <div class="buttons d-flex justify-content-between">
        <app-success-button [size]="'small'" (click)="addInfoPanelToCustomCasing()">{{'translate.form.yes' | translate}}</app-success-button>
        <app-danger-button [size]="'small'" (click)="currentStep='overview'">{{'translate.form.no' | translate}}</app-danger-button>
      </div>
    </ng-container>
    <ng-container *ngIf="currentStep === 'overview' && device.customCasing.customCasingId && device.backplate.backplateId">
      <app-h2 class="text-center">Het volgende toestel wordt aangemaakt</app-h2>
      <div class="overview">
        <div class="d-flex justify-content-between">
          <span>Klant:</span> <span>{{customCasing.clientName}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Variant:</span> <span>{{customCasing.clientVariantIdentifier}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Taal:</span> <span>{{customCasing.language}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Backplate_ID:</span> <span>{{device.backplate.backplateId}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Casing_ID:</span> <span>{{customCasing.casingId}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Season_ID:</span> <span>{{customCasing.seasonId}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Info bord:</span> <span>{{customCasing.hasInfoPanel ? 'JA' : 'NEE'}}</span>
        </div>
      </div>
      <app-warning-button [fullWidth]="true" (click)="resetCasing()">Verander casing</app-warning-button>
      <app-warning-button [fullWidth]="true" (click)="resetBackplate()">Verander backplate</app-warning-button>
      <app-success-button [fullWidth]="true" (click)="createDevice()">Volgende</app-success-button>
    </ng-container>
    <ng-container *ngIf="currentStep === 'finish'">
      <app-h2 class="text-center">Toestel succesvol aangemaakt</app-h2>
      <app-warning-button [fullWidth]="true" (click)="pdfGenerator.openPrintDialog(); userHasPrintedLabel = true">Print label</app-warning-button>
      <app-warning-button [fullWidth]="true" (click)="addCommentToDevice()">Voeg een opmerking toe</app-warning-button>
      <app-warning-button [fullWidth]="true" (click)="navigateToCreateSmot()">Maak Smot met dit toestel</app-warning-button>
      <app-warning-button [fullWidth]="true" (click)="router.navigate(['/production'], {queryParams: {screen: 'devices'}})" [disabled]="!userHasPrintedLabel" [matTooltip]="userHasPrintedLabel ? '' : 'translate.general.printLabelBeforeContinuing' | translate">Terug naar overzicht</app-warning-button>
      <app-label-generator #pdfGenerator [entityType]="'DEVICE'" [entity$]="device$"></app-label-generator>

    </ng-container>
    <ng-container *ngIf="currentStep === 'overview' && !device.customCasing.customCasingId">
      <app-h2 class="text-center">Scan de casing</app-h2>
      <app-js-qr-scanner
        [badScan]="badScan"
        [processingScan]="processingScan"
        (codeScanned)="handleCasingScan($event)"
      ></app-js-qr-scanner>
    </ng-container>
    <ng-container *ngIf="currentStep === 'overview' && !device.backplate.backplateId">
      <app-h2 class="text-center">Scan de backplate</app-h2>
      <app-js-qr-scanner
        [badScan]="badScan"
        [processingScan]="processingScan"
        (codeScanned)="handleBackplateScan($event)"
      ></app-js-qr-scanner>
    </ng-container>
  </div>

