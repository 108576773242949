<!-- SMOT -->
<ng-container *ngIf="smot$ | async as smot else loading">
  <div class="sundo-container mb-4">
    <form [formGroup]="smotForm" (ngSubmit)="saveSmot(smot)">
      <div class="row">
        <!--        SMOTSpot Image    /-->
        <div class="col-md-3 col-12 d-flex justify-content-center mb-3">
          <div class="smotspot-img-container">
            <ng-container *ngIf="smotImage$ | async as image else defaultImage">
              <img id="smot-img" [attr.src]="image"
                   alt="smotspot img"
                   class="smotspot-img editable">
            </ng-container>
            <ng-template #defaultImage>
              <img id="default-smot"
                   src='./assets/img/smotspots/default.jpg' alt="smotspot img"
                   class="smotspot-img editable">
            </ng-template>
            <app-primary-button class="center img-btn" (click)="fileUpload.click()">Edit Image</app-primary-button>
            <input type="file" class="d-none" accept="image/*"
                   (change)="onFileSelect($event, smot)" #fileUpload>
          </div>
        </div>
        <!--        Name, Battery, Client, Sunscreen    /-->
        <div class="col-md-4 col-12">
          <app-input-field
            [label]="'SMOTSpot name' | translate"
            [control]="smotNameControl"
            [placeholder]="'translate.operations.create-smot.clientDeviceCityPlaceholder' | translate"
          ></app-input-field>
          <app-input-field
            [label]="'Battery' | translate"
            [initialValue]="smot.battery"
            [disabled]="true"
          ></app-input-field>
          <app-input-field
            [label]="'Client' | translate"
            [initialValue]="smot.client?.clientName"
            [disabled]="true">
          </app-input-field>
          <app-input-field
            [label]="'Sunscreen' | translate"
            [initialValue]="(smot.packaging != null) ? smot.packaging?.sunscreen?.sunscreenType : 'No Sunscreen'"
            [disabled]="true">
          </app-input-field>
          <app-drop-down-field
            [control]="languageControl"
            [options$]="supportedLanguages$"
            [initialSelectedValue]="smot.language"
            [label]="'translate.user.language'"
          ></app-drop-down-field>
        </div>
        <div class="col-md-4 col-12 align-self-end">
          <app-input-field
            [label]="'Firmware' | translate"
            [initialValue]="smot.firmwareVersion"
            [disabled]="true">
          </app-input-field>
          <app-input-field
            [label]="'Remaining Volume' | translate"
            [initialValue]="(smot.packaging != null) ? smot.packaging.remainingVolume + ' ' : 'No Sunscreen'"
            [disabled]="true">
          </app-input-field>
          <app-input-field
            [label]="'Status' | translate"
            [initialValue]="smot.status"
            [disabled]="true">
          </app-input-field>
          <mat-slide-toggle style="height: 85px; width: 100%" [formControl]="eventControl">{{'Event' | translate}}</mat-slide-toggle>
          <mat-slide-toggle style="height: 50px" [formControl]="isOwnMaintenanceControl">{{'translate.create.maintenanceContract'  | translate}}</mat-slide-toggle>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-12 offset-md-3">
          <div class="form-group">
            <label for="startTime">{{'translate.smot.wakeupTime' | translate}}</label>
            <div class="d-flex">
              <input id="startTime" [formControl]="startTimeControl" class="form-control"
                     [ngxTimepicker]="startTimePicker"
                     [format]="24"
              />
              <ngx-material-timepicker-toggle [for]="startTimePicker"></ngx-material-timepicker-toggle>
              <ngx-material-timepicker matSuffix #startTimePicker [hoursOnly]="true" [enableKeyboardInput]="true"></ngx-material-timepicker>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="form-group">
            <label for="sleepTime">{{'translate.smot.sleepTime' | translate}}</label>
            <div class="d-flex">
              <input id="sleepTime" [formControl]="sleepTimeControl" class="form-control"
                     [ngxTimepicker]="sleepTimepicker"
                     [format]="24"
              />
              <ngx-material-timepicker-toggle [for]="sleepTimepicker"></ngx-material-timepicker-toggle>
              <ngx-material-timepicker matSuffix #sleepTimepicker [hoursOnly]="true"
                                       [enableKeyboardInput]="true"></ngx-material-timepicker>
            </div>
          </div>
        </div>
      </div>
      <!--        Buttons   /-->
      <div class="d-flex justify-content-around flex-wrap mt-3">
        <app-danger-button (click)="cancel()">{{ 'translate.form.cancel' | translate }}</app-danger-button>
        <app-warning-button><a style="color: white" href="https://maps.apple.com/?q={{smot.latitude}},{{smot.longitude}}" target="_blank"> {{ 'translate.general.navigate' | translate }} </a></app-warning-button>
        <app-success-button [type]="'submit'" [loading]="saveIsLoading" [disabled]="saveIsLoading">{{ 'translate.form.save' | translate }}</app-success-button>
        <div style="width: 250px;" class="d-flex mt-2">
          <mat-icon class="mr-3" [style.color]="notificationSettingColor">mail</mat-icon>
          <mat-select [formControl]="notificationControl" (selectionChange)="getColorBasedOnMailType()">
            <mat-option [value]="0">{{'translate.smot.smotNotifications.noNotifications' | translate}}</mat-option>
            <mat-option [value]="1">{{'translate.smot.smotNotifications.lightNotifications' | translate}}</mat-option>
            <mat-option [value]="2">{{'translate.smot.smotNotifications.allNotifications' | translate}}</mat-option>
          </mat-select>
        </div>

      </div>
    </form>
  </div>
  <ng-container *ngIf="!smot.device && smot.isActive !== 5 && canAccessDetails">
    <div class="no-device-found-info">
      <p>
        {{'translate.operations.view-smot.noDeviceFound' | translate}}
      </p>
    </div>
  </ng-container>
  <mat-accordion multi="true">
    <!-- SMOT ACTIONS -->
    <mat-expansion-panel *ngIf="canAccessDetails && device$ | async as device">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'translate.smot-detail.smotActions' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="d-flex justify-content-around flex-wrap">
        <app-warning-button class="m-1" [loading]="actionIsLoading" [disabled]="!device || actionIsLoading" (click)="rebootSmot(device)">{{ 'translate.operations.view-smot.reboot' | translate}}</app-warning-button>
        <app-warning-button class="m-1" [loading]="actionIsLoading" *ngIf="smot.isActive === 3 || smot.isActive === 2" [disabled]="!device || actionIsLoading" (click)="activateDispenser(device, smot)">{{ 'translate.operations.shared.activateSmot' | translate}}</app-warning-button>
        <app-warning-button class="m-1" [loading]="actionIsLoading" *ngIf="smot.isActive === 1 || smot.isActive === 2" [disabled]="!device || actionIsLoading" (click)="deactivateDispenser(device, smot)">{{ 'translate.operations.smot-operation.deactivateSmot' | translate}}</app-warning-button>
        <app-warning-button class="m-1" *ngIf="smot.isActive === 4" [loading]="actionIsLoading" [disabled]="!device || actionIsLoading" (click)="reinstallSmot(device, smot)">{{ 'translate.operations.view-smot.reinstall' | translate}}</app-warning-button>
        <app-warning-button class="m-1" *ngIf="smot.isActive !== 4" [loading]="actionIsLoading" [disabled]="!device || actionIsLoading" (click)="showConfirmDeinstallModal(smot)">{{ 'translate.operations.smot-operation.deleteSmot' | translate}}</app-warning-button>
        <app-warning-button class="m-1" [loading]="actionIsLoading" [disabled]="!device || actionIsLoading" (click)="updateFirmware(device)">{{ 'translate.operations.smot-operation.updateFirmware' | translate}}</app-warning-button>
        <ng-container *ngIf="user$ | async as user">
          <app-warning-button class="m-1" *ngIf="user?.hasAccessToRoutes" [loading]="actionIsLoading" [disabled]="!device || actionIsLoading" (click)="displayAddCommentModal(smot)">{{'translate.routes.addComment' | translate}}</app-warning-button>
          <app-warning-button class="m-1" *ngIf="user?.hasAccessToRoutes" [loading]="actionIsLoading" [disabled]="!device || actionIsLoading" (click)="displayAddCommentModal(smot, true)">{{'translate.routes.addInfo' | translate}}</app-warning-button>
        </ng-container>
      </div>
    </mat-expansion-panel>

    <!--  SMOT DETAILS -->
    <mat-expansion-panel *ngIf="canAccessDetails && smot.device">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'translate.smot-detail.smotData' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-smot-detail></app-smot-detail>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="canAccessDetails && smot.device" class="mb-3" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'translate.nav.operations' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-smot-operations></app-smot-operations>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
<ng-template #loading>
  <div class="sundo-container w-100 h-100">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
  </div>
</ng-template>


