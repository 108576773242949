import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomCasingService} from '../../../services/custom-casing.service';
import {ScannerService} from '../../../services/scanner.service';
import {ScannedQR} from '../../../models/ScannedQR';
import {filter, tap} from 'rxjs/operators';
import {CustomCasingDTO} from '../../../dtos/CustomCasingDTOs/customCasingDTO';
import {Observable} from 'rxjs';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmActionModalComponent} from '../../plugins/confirm-action-modal/confirm-action-modal.component';

@Component({
  selector: 'app-create-custom-casing',
  templateUrl: './create-custom-casing.component.html',
  styleUrls: ['./create-custom-casing.component.scss']
})
export class CreateCustomCasingComponent implements OnInit {
  currentStep: 'casingScan' | 'form' | 'seasonScan' | 'finish' = 'casingScan';
  customCasing$: Observable<CustomCasingDTO>;
  badScan: boolean = false;
  customCasing: CustomCasingDTO = {} as CustomCasingDTO;
  userHasPrintedLabel: boolean = false;

  constructor(private customCasingService: CustomCasingService,
              private toastr: ToastrNotificationService,
              public router: Router,
              private matDialog: MatDialog,
              private route: ActivatedRoute,
              private scanService: ScannerService) {
    this.route.queryParams.subscribe(params => {
      this.customCasing.casingId = params['casId'];
    });
  }

  ngOnInit(): void {
    if (this.customCasing?.casingId) {
      this.checkCasingAvailability(this.customCasing.casingId);
    }
  }

  public saveCustomCasing(customCasing: CustomCasingDTO) {
    customCasing = {
      ...customCasing,
      seasonId: this.customCasing.seasonId,
      casingId: this.customCasing.casingId,
    };
    if (this.customCasing?.customCasingId) {
      this.customCasingService.updateCustomCasing(customCasing).pipe(
        filter(x => x !== undefined),
        tap(result => {
          this.finishFlow(result);
          this.toastr.showSucces('Custom casing aangepast', 'Success');
        })
      ).subscribe();
    } else {
      this.customCasingService.createNewCustomCasing(customCasing).pipe(
        filter(x => x !== undefined),
        tap(result => {
          this.finishFlow(result);
          this.toastr.showSucces('Custom casing aangemaakt', 'Success');
        })
      ).subscribe();
    }
  }

  public handleCasingScan(code) {
    this.badScan = false;
    const scannedQR: ScannedQR = this.scanService.verifyScan(code);
    if (!scannedQR || scannedQR?.type !== 'casing') {
      this.badScan = true;
      return;
    }

    this.checkCasingAvailability(+scannedQR.casingId);
  }

  private checkCasingAvailability(casingId: number) {
    this.customCasingService.getCustomCasingByCasingId(casingId).pipe(
      filter(x => x !== undefined),
      tap(customCasing => {
        if (!customCasing) {
          this.currentStep = 'seasonScan';
          this.customCasing.casingId = casingId;
        } else {
          this.promptUserForCustomCasingUpdate(customCasing);
        }
      })
    ).subscribe();
  }

  public handleSeasonScan(code) {
    this.badScan = false;
    const scannedQR: ScannedQR = this.scanService.verifyScan(code);
    if (!scannedQR || scannedQR?.type !== 'season') {
      this.badScan = true;
      return;
    }

    if (this.customCasing?.customCasingId && this.customCasing.seasonId === +scannedQR.seasonId) {
      this.customCasing.seasonId = +scannedQR.seasonId;
      this.currentStep = 'form';
      return;
    }

    this.customCasingService.getCustomCasingBySeasonId(scannedQR.seasonId).pipe(
      filter(x => x !== undefined),
      tap(customCasing => {
        if (!customCasing) {
          this.customCasing.seasonId = +scannedQR.seasonId;
          this.currentStep = 'form';
        } else {
          this.toastr.showError('Season ID is already in use', 'Error');
        }
      })
    ).subscribe();
  }


  public navigateToCreateDevice() {
    this.router.navigate(['/production/create/device'], {
      queryParams: {
        casId: this.customCasing.casingId
      }
    });
  }

  private promptUserForCustomCasingUpdate(customCasing: CustomCasingDTO) {
    const dialogRef = this.matDialog.open(ConfirmActionModalComponent, {
      data: {
        key: 'Een custom casing met deze casing ID bestaat al, wil je deze aanpassen?',
        html: '<ul>' +
          `<li>Klant: ${customCasing.clientName}</li>` +
          `<li>Variant: ${customCasing.clientVariantIdentifier}</li>` +
          `<li>Season ID: ${customCasing.seasonId}</li>` +
          '</ul>'
      }
    });

    dialogRef.afterClosed().pipe(
      filter(x => x !== undefined),
      tap(response => {
        if (response) {
          this.currentStep = 'seasonScan';
          this.customCasing = customCasing;
        } else {
          this.customCasing.casingId = null;
          this.toastr.showError('Custom casing bestaat al', 'Error');
        }
      })
    ).subscribe();
  }

  private finishFlow(customCasing: CustomCasingDTO) {
    this.currentStep = 'finish';
    this.customCasing$ = this.customCasingService.getCustomCasingById(customCasing.customCasingId).pipe(
      filter(x => x !== undefined)
    );
  }
}
