import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {DeviceDTO} from '../../../dtos/deviceDTO';
import {CustomCasingDTO} from '../../../dtos/CustomCasingDTOs/customCasingDTO';
import {startWith, tap} from 'rxjs/operators';
import { jsPDF } from 'jspdf';
import {document} from 'ngx-bootstrap/utils';
import html2canvas from 'html2canvas';
import {CasingDTO} from '../../../dtos/casingDTO';

@Component({
  selector: 'app-label-generator',
  templateUrl: './label-generator.component.html',
  styleUrls: ['./label-generator.component.scss']
})
export class LabelGeneratorComponent implements OnInit, AfterViewInit {
  @Input() entityType: 'CASING' | 'CUSTOM' | 'DEVICE' | 'SMOT' = 'SMOT';
  @Input() entity$: Observable<SmotDTO | DeviceDTO | CustomCasingDTO | CasingDTO> = new Observable<SmotDTO | DeviceDTO | CustomCasingDTO>();
  barcodeValue = null;

  locationDescription: string;
  logicalId: number;
  deviceId: number | string;
  casingId: number | string;
  backplateId: number | string;
  client: string;
  variant: string;
  language: string;
  supplier: string;
  productionYear: number;
  state: string;
  hasInfoPanel: boolean;

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  private mapEntityToComponentProperties() {
    return this.entity$.pipe(
      tap(entity => {
        const isCasing = 'casingId' in entity && 'productionYear' in entity;
        const isSmot = 'logicalId' in entity;
        const isDevice = 'deviceId' in entity && !('logicalId' in entity);
        const isCustomCasing = 'customCasingId' in entity && !('logicalId' in entity || 'deviceId' in entity);

        if (isCasing) {
          this.barcodeValue = 'CAS_' + String(entity.casingId).padStart(5, '0');
          this.casingId = entity.casingId ?? 'ERROR';
          this.supplier = entity.supplier;
          this.productionYear = entity.productionYear;
          this.state = entity.state;
        } else if (isCustomCasing) {
          this.barcodeValue = 'CUS_' + String(entity.customCasingId).padStart(5, '0');
          const casing: CustomCasingDTO = {
            ...entity
          } as CustomCasingDTO;
          this.client = casing?.clientName;
          this.variant = casing?.clientVariantIdentifier;
          this.language = casing?.language;
          this.casingId = casing?.casingId ?? 'ERROR';
          this.hasInfoPanel = entity.hasInfoPanel;
        } else if (isDevice) {
          const device: DeviceDTO = {
            ...entity
          } as DeviceDTO;
          this.barcodeValue = 'DEV_' + String(entity?.deviceId).padStart(5, '0');
          this.casingId = device?.casingId ?? 'ERROR';
          this.backplateId = device?.backplate?.backplateId ?? 'ERROR';
          this.client = device?.customCasing?.clientName;
          this.variant = device?.customCasing?.clientVariantIdentifier;
          this.language = device?.customCasing?.language;
          this.hasInfoPanel = device?.customCasing?.hasInfoPanel;
        } else if (isSmot) {
          this.barcodeValue = 'SMOT_' + String(entity?.logicalId).padStart(5, '0');
          this.locationDescription = entity.locationDescription;
          this.logicalId = entity?.logicalId;
          this.casingId = entity?.device?.customCasing?.casingId  ?? 'ERROR';
          this.backplateId = entity?.device?.backplate?.backplateId  ?? 'ERROR';
          this.client = entity?.client?.clientName;
          this.variant = entity?.device?.customCasing?.clientVariantIdentifier;
          this.language = entity?.language;
          this.hasInfoPanel = entity?.device?.customCasing?.hasInfoPanel;
        }

        this.ref.detectChanges();
      })
    );
  }

  public openPrintDialog() {
    this.mapEntityToComponentProperties().pipe(
      tap(() => {
        const element = document.getElementById('label-container');
        html2canvas(element, {
          height: 1240,
          width: 1748,
        }).then(canvas => {
          const pdf = new jsPDF('landscape', 'mm', 'a5', false);
          const img = canvas.toDataURL('image/png');
          pdf.addImage(img, 'PNG', 0, 0, 210, 148);
          // Automatically opens the print dialog when the PDF is opened
          pdf.autoPrint();

          // Creates a new page with the PDF
          const pdfBlob = pdf.output('blob');
          const url = URL.createObjectURL(pdfBlob);
          const printWindow = window.open(url, '_blank');
          printWindow.print();
        });
      })
    ).subscribe();
  }
}
