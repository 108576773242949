  <div class="sundo-container h-100 m-auto medium position-relative">
    <ng-container *ngIf="currentStep === 'casingScan'">
      <app-h2 class="text-center">Scan de casing</app-h2>
      <app-js-qr-scanner [badScan]="badScan" (codeScanned)="handleCasingScan($event)"></app-js-qr-scanner>
    </ng-container>
    <ng-container *ngIf="currentStep === 'seasonScan'">
      <app-h2 class="text-center">Scan de seasonId</app-h2>
      <app-js-qr-scanner [badScan]="badScan" (codeScanned)="handleSeasonScan($event)"></app-js-qr-scanner>
    </ng-container>
    <ng-container *ngIf="currentStep === 'form'">
      <app-create-custom-casing-form [customCasing]="customCasing" (customCasingCreated)="saveCustomCasing($event)"></app-create-custom-casing-form>
    </ng-container>
    <ng-container *ngIf="currentStep === 'finish' && customCasing$ | async as customCasing">
      <div class="overview">
        <div class="d-flex justify-content-between">
          <span>Klant: </span> <span>{{customCasing.clientName}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Variant: </span> <span>{{customCasing.clientVariantIdentifier}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Casing_ID: </span> <span>{{customCasing.casingId}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Season_ID: </span> <span>{{customCasing.seasonId}}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span>Bavat Info bord: </span> <span>{{customCasing.hasInfoPanel ? 'JA' : 'NEE'}}</span>
        </div>
      </div>

      <app-warning-button [fullWidth]="true" (click)="pdfGenerator.openPrintDialog();userHasPrintedLabel=true">Print label</app-warning-button>
      <app-warning-button [fullWidth]="true" (click)="navigateToCreateDevice()">Maak Toestel met deze casing</app-warning-button>
      <app-warning-button [fullWidth]="true" (click)="router.navigate(['/production'], {queryParams: {screen: 'custom-casings'}})" [disabled]="!userHasPrintedLabel" [matTooltip]="userHasPrintedLabel ? '' : 'translate.general.printLabelBeforeContinuing' | translate">Terug naar overzicht</app-warning-button>
      <app-label-generator #pdfGenerator [entityType]="'CUSTOM'" [entity$]="customCasing$"></app-label-generator>
    </ng-container>
  </div>
