import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseFlowStepComponent} from '../base-flow-step.interface';
import {Observable, of} from 'rxjs';
import {filter, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {MatCheckboxChange} from '@angular/material/checkbox';
import moment from 'moment/moment';
import { RouteFlowStepData } from '../../../../../models/routeFlows';
import { PlannedRouteDTO } from '../../../../../dtos/RouteDTOs/plannedRouteDTO';
import { RouteStepDTO } from '../../../../../dtos/RouteDTOs/routeDTO';
import { SmotService } from '../../../../../services/smot.service';
import { MobileService } from '../../../../../services/mobile.service';
import { RouteService } from '../../../../../services/route.service';
import { ResolutionStatus } from '../../../../../models/resolutionStatus';
import { SkippedCommentDTO } from '../../../../../dtos/skippedCommentDTO';
import { CommentType } from '../../../../../models/commentType';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss', '../../../../operations/operations.scss']
})
export class CommentsComponent implements OnInit, BaseFlowStepComponent {
  @Input() data: RouteFlowStepData;
  @Output() nextStep: EventEmitter<true> = new EventEmitter();
  @Output() previousStep: EventEmitter<false> = new EventEmitter();

  plannedRoute$: Observable<PlannedRouteDTO>;
  step: RouteStepDTO;

  comments$: Observable<any>;
  infoComments$: Observable<any>;

  comments = null;
  infoComments = null;
  constructor(private smotService: SmotService,
              private mobileService: MobileService,
              private routeService: RouteService) {}

  ngOnInit(): void {
    if (this.data.plannedRouteId) {
      this.fetchPlannedRoute();
    }
    this.fetchComments();
  }

  public goToPreviousStep() {
    this.previousStep.emit(false);
  }

  public goToNextStep() {
    if (!this.data.requiredToCheckAllComments || !this.data.plannedRouteId) {
      this.nextStep.emit(true);
      return;
    }

    // TODO: Updated values for comments not updated in database
    this.postSkippedComments().pipe(
      switchMap(() => this.plannedRoute$.pipe(
        switchMap(plannedRoute => {
          const allComments = this.comments.concat(this.infoComments);
          const stepIndex = plannedRoute.route.steps.findIndex(step => step.routeStepId === +this.data.stepId);
          const routeStep = plannedRoute.route.steps[stepIndex];
          if (routeStep.smot) {
            routeStep.smot.routeStepComments = allComments;
          } else {
            routeStep.routeStepComments = allComments;
          }
          plannedRoute.route.steps[stepIndex] = routeStep;
          return this.routeService.updatePlannedRoute(plannedRoute);
        }),
        filter(x => x !== undefined),
        tap(() => this.nextStep.emit(true)),
      )),
    ).subscribe();
  }

  public skipComment(comment) {
    comment.skippedComment = true;
    comment.resolveDate = null;
    comment.resolutionStatus = ResolutionStatus.UNRESOLVED;
  }

  public updateComment(comment, event: MatCheckboxChange) {
    comment.skippedComment = false;
    comment.resolveDate = event.checked ? moment().utc() : null;
    comment.resolutionStatus = event.checked ? ResolutionStatus.RESOLVED : ResolutionStatus.UNRESOLVED;
  }


  public hasUnresolvedComments() {
    // const unresolvedMaintenance = this.maintenances.find(m => !m.checked && !m.skipped);
    return !!this.comments?.find(c => c.resolutionStatus === ResolutionStatus.UNRESOLVED && !c.skippedComment);
  }

  public navigateWithWaze(step: RouteStepDTO) {
    const lat = step.smot ? step.smot?.latitude : step.latitude;
    const lng = step.smot ? step.smot?.longitude : step.longitude;
    if (this.mobileService.detectMobileDeviceByUserAgent()) {
      window.open(`waze://?ll=${lat},${lng}&navigate=yes`, '_blank');
    } else {
      window.open(`https://waze.com/ul?ll=${lat}%2C${lng}&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location`, '_blank');
    }
  }

  public navigateWithGoogleOrAppleMaps(step: RouteStepDTO) {
    const lat = step.smot ? step.smot?.latitude : step.latitude;
    const lng = step.smot ? step.smot?.longitude : step.longitude;

    window.open(`https://maps.apple.com/?q=${lat},${lng}`, '_blank');
  }

  private postSkippedComments() {
    let comments = this.comments.concat(this.infoComments).filter(x => x.skippedComment);
    comments = comments.map(comment => ({
      reason: comment.reason,
      routeStepCommentId: comment.routeStepCommentId
    }) as SkippedCommentDTO);

    if (comments.length <= 0) {
      return of(null);
    }

    return this.routeService.createSkippedComments(this.data.plannedRouteId, this.data.stepId, comments).pipe(
      filter(x => x !== undefined),
    );
  }

  private fetchComments() {
    // Fetch Smot Comments, else Fetch step comments
    let allComments$: Observable<any>;
    if (this.data.smotId) {
      allComments$ = this.smotService.getSmot(this.data.smotId).pipe(
        filter(x => x !== undefined),
        map(smot => smot.comments),
        shareReplay(1)
      );
    } else {
      allComments$ = this.plannedRoute$.pipe(
        map(plannedRoute => {
          const routeStep = plannedRoute?.route?.steps.find(step => step.routeStepId === +this.data.stepId);
          if (routeStep?.smot) {
            return routeStep?.smot?.routeStepComments;
          } else {
            return routeStep?.routeStepComments;
          }
        }),
        shareReplay(1)
      );
    }

    this.comments$ = allComments$.pipe(
      map(comments => comments?.filter(c => c.type === CommentType.COMMENT)),
      tap(comments => this.comments = comments)
    );

    this.infoComments$ = allComments$.pipe(
      map(comments => comments?.filter(c => c.type === CommentType.INFO)),
      tap(comments => this.infoComments = comments)
    );
  }

  private fetchPlannedRoute() {
    this.plannedRoute$ = this.routeService.getPlannedRouteById<PlannedRouteDTO>(this.data.plannedRouteId).pipe(
      filter(x => x !== undefined),
      shareReplay(1)
    );

    this.plannedRoute$.subscribe(plannedRoute => {
      this.step = plannedRoute?.route?.steps.find(step => step.routeStepId === +this.data.stepId);
      if (this.step && !this.step.smot) {
        this.data.headerString = this.step.content;
      }
    });
  }


  // TODO: Add maintenances?
  // public updateMaintenance(maintenance, event: MatCheckboxChange) {
  //   maintenance.skipped = false;
  //   maintenance.repairUsername = this.auth.getUsername();
  //   maintenance.resolveDate = event.checked ? moment().utc() : null;
  //   maintenance.repairStatus = event.checked ? 1 : 0;
  // }
  //
  // public skipMaintenance(maintenance) {
  //   maintenance.skipped = true;
  //   maintenance.checked = false;
  // }

  // private postMaintenance() {
  //   const maintenancesToUpdate = this.maintenances.filter(m => m.checked && !m.skipped);
  //
  //   maintenancesToUpdate.forEach(m => {
  //     this.maintenanceService.updateMaintenance(m.maintenanceId, m).subscribe();
  //   });
  // }

  // this.maintenance$ = this.maintenanceService.getMaintenanceBySmotLogicalId(step.smot.logicalId).pipe(
  //   filter(x => x !== undefined),
  //   map(maintenances => maintenances.map(maintenance => ({
  //     ...maintenance,
  //     checked: false,
  //     description: `${maintenance.repairPart} ${this.translate.instant('translate.routes.repair')}`
  //   }) as MaintenanceDTO)),
  //   tap(maintenances => {
  //     this.maintenances = maintenances;
  //   })
  // );
  // null if step does not contain a smot
}
