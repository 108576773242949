import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {ApiHelperService} from './api-helper.service';
import {Sort} from '@angular/material/sort';
import {CustomCasingDTO} from '../dtos/CustomCasingDTOs/customCasingDTO';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomCasingService {
  private customCasingUrl = environment.apiRoot + '/custom-casings';

  constructor(private helper: ApiHelperService) { }

  /**
   * GET all custom casings with pagination
   */
  getCustomCasings(page: number | null = null, size: number | null = null, search: string = '', sort: Sort = null) {
    let url = this.customCasingUrl;
    url += this.helper.getPaginationString(page, size, search);
    url += this.helper.getSortString(sort);
    return this.helper.getRequest(url);
  }

  /**
   * GET all available custom casings with pagination
   */
  getAvailableCustomCasings(page: number | null = null, size: number | null = null, search: string = '', sort: Sort = null, clientNumber: number, language: string, clientVariantNumber: number) {
    let url = this.customCasingUrl;
    url += this.helper.getPaginationString(page, size, search);
    url += this.helper.getSortString(sort);
    url += '&fetchType=available';
    if (clientNumber) {
      url += '&clientNumber=' + clientNumber;
    }
    if (language) {
      url += '&language=' + language;
    }
    if (clientVariantNumber) {
      url += '&clientVariantNumber=' + clientVariantNumber;
    }
    return this.helper.getRequest(url);
  }

  /**
   * GET custom casing by casing ID
   */
  getCustomCasingByCasingId(casingId: number): Observable<CustomCasingDTO> {
    return this.helper.getRequest(this.customCasingUrl + '/casing/' + casingId);
  }

  /**
   * GET custom casing by season ID
   */
  getCustomCasingBySeasonId(seasonId: string) {
    return this.helper.getRequest(this.customCasingUrl + '/seasonId/' + seasonId);
  }

  /**
   * GET custom casing by season ID or casing ID
   */
  getCustomCasingByCasingIdOrSeasonId(casingId: string, seasonId: string): Observable<CustomCasingDTO> {
    const url = `${this.customCasingUrl}/find?casingId=${+casingId ?? 0}&seasonId=${+seasonId ?? 0}`;
    return this.helper.getRequest(url);
  }

  /**
   * GET custom casing by custom casing ID
   */
  getCustomCasingById(id: number) {
    return this.helper.getRequest<CustomCasingDTO>(this.customCasingUrl + '/' + id);
  }

  /**
   * POST request to create a new customCasing
   */
  createNewCustomCasing(customCasing: CustomCasingDTO): Observable<CustomCasingDTO> {
    return this.helper.postRequest(this.customCasingUrl, customCasing);
  }

  /**
   * PUT request to update an existing customCasing
   */
  updateCustomCasing(customCasing: CustomCasingDTO): Observable<CustomCasingDTO> {
    return this.helper.putRequest(this.customCasingUrl, customCasing);
  }

  /**
   * DELETE request to delete an existing customCasing by id
   */
  deleteCustomCasing(id: number) {
    const url = `${this.customCasingUrl}/${id}`;
    return this.helper.deleteRequest(url, null);
  }

  /**
   * Check if the given object is an instance of a CustomCasing
   * @param obj
   */
  instanceOfCustomCasing(obj: any) {
    return 'customCasingId' in obj && !('logicalId' in obj || 'deviceId' in obj);
  }
}
