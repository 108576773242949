import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditImgModalComponent} from './edit-img-modal/edit-img-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {ConfirmDeleteModalComponent} from './confirm-delete-modal/confirm-delete-modal.component';
import {ConfirmActionModalComponent} from './confirm-action-modal/confirm-action-modal.component';
import {InfoModalComponent} from './info-modal/info-modal.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProgressStepComponent} from './progress-step/progress-step.component';
import {AddSmotToMapModalComponent} from './add-smot-to-map-modal/add-smot-to-map-modal.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {SaveRouteAsModalComponent} from './save-route-as-modal/save-route-as-modal.component';
import {InputModalComponent} from './input-modal/input-modal.component';
import {FormFieldsModule} from '../../components/form-fields/form-fields.module';
import {RouteStepUpdateModalComponent} from './route-step-update-modal/route-step-update-modal.component';
import {SundoHeadersModule} from '../../components/headers/sundo-headers.module';
import {SundoButtonsModule} from '../../components/buttons/sundo-buttons.module';
import { UpdateAddressModalComponent } from './update-address-modal/update-address-modal.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AddPreparationFileInfoModalComponent } from './add-preparation-file-info-modal/add-preparation-file-info-modal.component';
import { BasicInfoModalComponent } from './basic-info-modal/basic-info-modal.component';
import { ProductionSmotRepairComponent } from './production-smot-repair/production-smot-repair.component';
import {ScannerModule} from '../../components/scanner/scanner.module';


@NgModule({
  declarations: [
    EditImgModalComponent,
    ConfirmDeleteModalComponent,
    ConfirmActionModalComponent,
    InfoModalComponent,
    ProgressStepComponent,
    AddSmotToMapModalComponent,
    SaveRouteAsModalComponent,
    InputModalComponent,
    RouteStepUpdateModalComponent,
    UpdateAddressModalComponent,
    AddPreparationFileInfoModalComponent,
    BasicInfoModalComponent,
    ProductionSmotRepairComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    MatExpansionModule,
    MatListModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    ReactiveFormsModule,
    FormFieldsModule,
    SundoHeadersModule,
    SundoButtonsModule,
    MatProgressSpinnerModule,
    ScannerModule
  ],
  exports: [
    EditImgModalComponent,
    ConfirmDeleteModalComponent,
    ConfirmActionModalComponent,
    ProgressStepComponent
  ]})
export class PluginsModule { }
