import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {Router} from '@angular/router';
import {SmotService} from '../../../services/smot.service';
import {filter, tap} from 'rxjs/operators';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-create-configuration',
  templateUrl: './create-configuration.component.html',
  styleUrls: ['./create-configuration.component.scss']
})
export class CreateConfigurationComponent implements OnInit {
  @Input() componentUsedAsChild: boolean = false;
  @Input() smot: SmotDTO;
  @Output() createdConfiguration: EventEmitter<SmotDTO> = new EventEmitter();

  constructor(private toastr: ToastrNotificationService,
              private router: Router,
              private smotService: SmotService,
              @Optional() public dialogRef: MatDialogRef<CreateConfigurationComponent>) { }

  ngOnInit(): void {
  }

  public async handleSmotHasBeenCreated(smot) {
    this.smotService.createSmotSpotConfiguration(smot).pipe(
      filter(x => x !== undefined),
      tap(async (config) => {
        this.toastr.showSucces('Successfully created a new configuration', 'Success');
        if (this.componentUsedAsChild) {
          this.createdConfiguration.emit(config);
        } else if (this.dialogRef) {
          this.dialogRef.close(smot);
        } else {
          await this.router.navigate(['/production']);
        }
      })
    ).subscribe();
  }
}
