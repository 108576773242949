<div class="d-flex justify-content-center">
  <form style="width: 300px" [formGroup]="smotFormGroup" novalidate (ngSubmit)="onSubmit()">
    <div class="form-group">
      <app-input-field [placeholder]="'translate.operations.create-smot.clientDeviceCityPlaceholder' | translate" [control]="smotName" [label]="'translate.operations.create-smot.smotName' | translate"></app-input-field>
    </div>
    <ng-container *ngIf="displayClientControls">
      <div class="form-group">
        <app-drop-down-field
          [search]="true"
          [label]="'translate.create.client' | translate"
          [options$]="clients$"
          [disabled]="disableClient"
          [control]="smotClient"
          (selected)="handleClientSelection($event)"
          [initialSelectedValue]="smotClient.value"
        ></app-drop-down-field>
      </div>
      <div class="form-group">
        <app-drop-down-field
          [search]="true"
          [label]="'translate.smot-detail.smotInfo.clientVariant' | translate"
          [options$]="clientVariants$"
          [control]="smotClientVariant"
          [disabled]="disableVariantControl"
          [initialSelectedValue]="smotClientVariant.value"
          (selected)="handleClientVariantSelection($event)"
        ></app-drop-down-field>
      </div>
    </ng-container>
    <div class="form-group">
      <app-drop-down-field
        [label]="'translate.create.language' | translate"
        [options$]="languages$"
        [initialSelectedValue]="smotLanguage.value"
        [control]="smotLanguage">
      </app-drop-down-field>
    </div>
    <div class="d-flex justify-content-between">
      <div class="form-group">
        <label class="col-form-label" for="wakeUpTime">{{ 'translate.smot.wakeupTime' | translate}}</label>
        <div style="display: flex">
          <input id="wakeUpTime" name="wakeUpTime" [formControl]="smotStartup" class="form-control"
                 [ngxTimepicker]="wakeUpTimepicker"
                 [format]="24"/>
          <ngx-material-timepicker-toggle [for]="wakeUpTimepicker"></ngx-material-timepicker-toggle>
          <ngx-material-timepicker matSuffix #wakeUpTimepicker [hoursOnly]="true"
                                   [enableKeyboardInput]="true"></ngx-material-timepicker>
        </div>
      </div>
      <div class="form-group">
        <label class="col-form-label" for="sleepTime">{{'translate.smot.sleepTime' | translate}}</label>
        <div style="display: flex">
          <input id="sleepTime" [formControl]="smotSleep" class="form-control"
                 [ngxTimepicker]="sleepTimepicker"
                 [format]="24"
          />
          <ngx-material-timepicker-toggle [for]="sleepTimepicker"></ngx-material-timepicker-toggle>
          <ngx-material-timepicker matSuffix #sleepTimepicker [hoursOnly]="true"
                                   [enableKeyboardInput]="true"></ngx-material-timepicker>
        </div>
      </div>
    </div>
    <div class="form-group">
      <mat-slide-toggle [formControl]="smotEvent">{{'translate.create.event' | translate}}</mat-slide-toggle>
    </div>
    <div class="form-group">
      <mat-slide-toggle [formControl]="isOwnMaintenanceControl">{{'translate.create.maintenanceContract' | translate}}</mat-slide-toggle>
    </div>
    <div class="d-flex justify-content-around flex-wrap">
      <app-success-button [type]="'submit'" [disabled]="disableButtons" [class.disabled]="disableButtons">{{'translate.form.next' | translate}}</app-success-button>
      <app-danger-button [type]="'submit'" (click)="smotFormGroup.reset()">{{'translate.form.reset' | translate}}</app-danger-button>
    </div>
  </form>
</div>
