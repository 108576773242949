import {Component, OnInit} from '@angular/core';
import {DeviceDTO} from '../../../dtos/deviceDTO';
import {ScannedQR} from '../../../models/ScannedQR';
import {filter, switchMap, tap} from 'rxjs/operators';
import {CustomCasingService} from '../../../services/custom-casing.service';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {ScannerService} from '../../../services/scanner.service';
import {DeviceService} from '../../../services/device.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomCasingDTO} from '../../../dtos/CustomCasingDTOs/customCasingDTO';
import {ConfirmActionModalComponent} from '../../plugins/confirm-action-modal/confirm-action-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {Observable, of} from 'rxjs';
import {InputModalComponent} from '../../plugins/input-modal/input-modal.component';
import moment from 'moment';
import {AuthenticationService} from '../../../services/authentication.service';
import {CommentType} from '../../../models/commentType';
import {ResolutionStatus} from '../../../models/resolutionStatus';
import {RouteService} from '../../../services/route.service';
import {CreateRouteStepCommentDTO} from '../../../dtos/RouteDTOs/createRouteDTO';

@Component({
  selector: 'app-create-device',
  templateUrl: './create-device.component.html',
  styleUrls: ['./create-device.component.scss']
})
export class CreateDeviceComponent implements OnInit {
  currentStep: 'casing' | 'backplate' | 'overview' | 'finish' | 'checkInfoPanel' = 'casing';
  device: DeviceDTO = {backplate: {}, customCasing: {}} as DeviceDTO;
  device$: Observable<DeviceDTO>;
  processingScan: boolean = false;
  customCasing: CustomCasingDTO;
  badScan: boolean = false;
  casingId: string;
  userHasPrintedLabel: boolean = false;

  constructor(private scanService: ScannerService,
              private deviceService: DeviceService,
              private customCasingService: CustomCasingService,
              public router: Router,
              private matDialog: MatDialog,
              private auth: AuthenticationService,
              private routeService: RouteService,
              private toastr: ToastrNotificationService,
              public route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.casingId = params['casId'];
    });
  }

  ngOnInit(): void {
    if (this.casingId) {
      this.checkDeviceAvailability(this.casingId, null);
    }
  }

  public handleCasingScan(code) {
    this.badScan = false;
    this.processingScan = true;
    const scannedQR: ScannedQR = this.scanService.verifyScan(code);
    if (!scannedQR || !['casing', 'custom-casing'].includes(scannedQR.type) ) {
      this.badScan = true;
      this.processingScan = false;
      return;
    }

    this.checkDeviceAvailability(scannedQR.casingId, scannedQR.customCasingId);
  }

  private checkDeviceAvailability(casingId: string, customCasingId: string) {
    if (casingId) {
      this.deviceService.getDeviceByCasing(casingId).pipe(
        filter(x => x !== undefined),
        tap(device => {
          if (!device) {
            this.fetchCustomCasing(+casingId, null);
          } else {
            this.promptUserToReplaceCasing(device);
          }
          this.processingScan = false;
        })
      ).subscribe();
    } else {
      this.deviceService.getDeviceByCustomCasingId(customCasingId).pipe(
        filter(x => x !== undefined),
        tap(device => {
          if (!device) {
            this.fetchCustomCasing(null, +customCasingId);
          } else {
            this.promptUserToReplaceCasing(device);
          }
          this.processingScan = false;
        })
      ).subscribe();
    }
  }

  public handleBackplateScan(code) {
    this.badScan = false;
    this.processingScan = true;
    const scannedQR: ScannedQR = this.scanService.verifyScan(code);
    if (!scannedQR || scannedQR?.type !== 'backplate') {
      this.badScan = true;
      this.processingScan = false;
      return;
    }

    this.deviceService.getDeviceByBackplate(scannedQR.backplateId).pipe(
      filter(x => x !== undefined),
      tap(device => {
        if (!device) {
          this.device.backplate.backplateId = +scannedQR.backplateId;
          if (!this.customCasing.hasInfoPanel) {
            this.currentStep = 'checkInfoPanel';
          } else {
            this.currentStep = 'overview';
          }
        } else {
          this.promptUserToReplaceBackplate(device);
        }
        this.processingScan = false;
      })
    ).subscribe();
  }

  public createDevice() {
    this.deviceService.createDevice(this.device).pipe(
      filter(x => x !== undefined),
      tap(async (device) => {
        this.device = device;
        this.device$ = of(device);
        this.toastr.showSucces('Device successfully created', 'Success');
        this.currentStep = 'finish';
      })
    ).subscribe();
  }

  public resetBackplate() {
    this.device.backplate.backplateId = null;
  }

  public resetCasing() {
    this.device.customCasing.customCasingId = null;
  }

  public navigateToCreateSmot() {
    this.router.navigate(['/production/create/smotspot'], {
      queryParams: {
        deviceId: this.device.deviceId
      }
    });
  }

  public addCommentToDevice() {
    const dialogRef = this.matDialog.open(InputModalComponent, {
      data: {
        key: 'translate.routes.addComment'
      }
    });

    dialogRef.afterClosed().pipe(
      filter(x => x !== undefined),
      switchMap(value => {
        const deviceComment = {
          content: value,
          creationDate: moment.utc(),
          username: this.auth.getUsername(),
          type: CommentType.COMMENT,
          resolutionStatus: ResolutionStatus.UNRESOLVED,
          deviceId: this.device.deviceId
        } as CreateRouteStepCommentDTO;

        return this.routeService.createComment(deviceComment);
      }),
      filter(x => x !== undefined),
      tap(() => this.toastr.showSucces('Opmerking toegevoegd', 'Success'))
    ).subscribe();
  }

  public addInfoPanelToCustomCasing() {
    this.customCasing.hasInfoPanel = true;
    this.customCasingService.updateCustomCasing(this.customCasing).pipe(
      filter(x => x !== undefined),
      tap(() => {
        this.toastr.showSucces('Info bord toegevoegd', 'Success');
        this.currentStep = 'overview';
      })
    ).subscribe();
  }

  private fetchCustomCasing(casingId: number, customCasingId: number) {
    if (casingId) {
      this.customCasingService.getCustomCasingByCasingId(casingId).pipe(
        filter(x => x !== undefined),
        tap(customCasing => {
          if (!customCasing) {
            this.toastr.showError('Custom casing bestaat nog niet', 'Error');
          } else {
            this.device.customCasing.customCasingId = customCasing.customCasingId;
            this.customCasing = customCasing;
            this.currentStep = 'backplate';
          }
        })
      ).subscribe();
    } else {
      this.customCasingService.getCustomCasingById(customCasingId).pipe(
        filter(x => x !== undefined),
        tap(customCasing => {
          if (!customCasing) {
            this.toastr.showError('Custom casing bestaat nog niet', 'Error');
          } else {
            this.device.customCasing.customCasingId = customCasing.customCasingId;
            this.customCasing = customCasing;
            this.currentStep = 'backplate';
          }
        })
      ).subscribe();
    }

  }

  private promptUserToReplaceCasing(device: DeviceDTO) {
    const dialogRef = this.matDialog.open(ConfirmActionModalComponent, {
      data: {
        key: 'Een device met deze casing bestaat al, wil je toch deze casing gebruiken?',
        html: '<ul>' +
          `<li>Device ID: ${device.deviceId}</li>` +
          `<li>Casing ID: ${device.casingId}</li>` +
          `<li>Backplate ID: ${device.backplate?.backplateId > 0 ? device.backplate.backplateId : 'ERROR'}</li>` +
          '</ul>'
      }
    });

    dialogRef.afterClosed().pipe(
      filter(x => x !== undefined),
      tap(response => {
        if (response) {
          this.fetchCustomCasing(device.casingId, null);
        } else {
          this.toastr.showError('Deze backplate is al in gebruik', 'Error');
        }
      })
    ).subscribe();
  }

  private promptUserToReplaceBackplate(device: DeviceDTO) {
    const dialogRef = this.matDialog.open(ConfirmActionModalComponent, {
      data: {
        key: 'Een device met deze Backplate bestaat al, wil je toch deze backplate gebruiken?',
        html: '<ul>' +
          `<li>Device ID: ${device.deviceId}</li>` +
          `<li>Casing ID: ${device.casingId > 0 ? device.casingId : 'ERROR'}</li>` +
          `<li>Backplate ID: ${device.backplate.backplateId}</li>` +
          '</ul>'
      }
    });

    dialogRef.afterClosed().pipe(
      filter(x => x !== undefined),
      tap(response => {
        if (response) {
          this.device.backplate.backplateId = device.backplate.backplateId;
          this.currentStep = 'overview';
        } else {
          this.toastr.showError('Deze backplate is al in gebruik', 'Error');
        }
      })
    ).subscribe();
  }

}
