import {Injectable} from '@angular/core';
import {ScannedQR} from '../models/ScannedQR';
import {SmotService} from './smot.service';
import {DeviceService} from './device.service';
import {CustomCasingService} from './custom-casing.service';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {Observable, of } from 'rxjs';
import {CasingService} from './casing.service';
import {BackplateService} from './backplate.service';

@Injectable({
  providedIn: 'root'
})
export class ScannerService {

  backplateRegexFormat: RegExp;
  casingRegexFormat: RegExp;
  sunscreenRegexFormat: RegExp;
  seasonRegexFormat: RegExp;
  deviceIdRegexFormat: RegExp;
  customCasingRegexFormat: RegExp;
  smotRegexFormat: RegExp;

  constructor(private smotService: SmotService,
              private deviceService: DeviceService,
              private customCasingService: CustomCasingService,
              private backplateService: BackplateService,
              private casingService: CasingService) {
    this.backplateRegexFormat = new RegExp('^.*bck=[0-9]{5}$');
    this.customCasingRegexFormat = new RegExp('^.*cus=[0-9]{5}$');
    this.casingRegexFormat = new RegExp('^.*cas=[0-9]{5}$');
    this.sunscreenRegexFormat = new RegExp('^.*sun=[0-9]{6}$');
    this.seasonRegexFormat = new RegExp('^.*sea=[0-9]{5}$');
    this.deviceIdRegexFormat = new RegExp('^.*dev=[0-9]{5}$');
    this.smotRegexFormat = new RegExp('^.*smot=[0-9]{5}$');
  }

  verifyScan(scannedText: string): ScannedQR {
    if (!scannedText) {
      return null;
    }

    // This replaces the '-' or '_' in the manual code to a '=' to fit the RegExp
    const tempText = scannedText.slice(scannedText.length - 9).replace(/-|_/, '=');
    scannedText = scannedText.slice(0, scannedText.length - 9);
    scannedText += tempText;

    // To lower case in case the url contains uppercases in for the RegExp (e.g CAS, Bck, Sun)
    scannedText = scannedText.toLocaleLowerCase();
    if (this.isCasingScan(scannedText)) {
      const cas = scannedText.slice(scannedText.length - 5);
      return {
        type: 'casing',
        casingId: cas,
      };
    }
    if (this.isCustomCasingScan(scannedText)) {
      const customCasingId = scannedText.slice(scannedText.length - 5);
      return {
        type: 'custom-casing',
        customCasingId: customCasingId,
      };
    }
    if (this.isBackplateScan(scannedText)) {
      const bck = scannedText.slice(scannedText.length - 5);
      return {
        type: 'backplate',
        backplateId: bck
      };
    }
    if (this.isSunscreenScan(scannedText)) {
      const sun = scannedText.slice(scannedText.length - 6);
      return {
        type: 'sunscreen',
        sunscreenId: sun
      };
    }
    if (this.isSeasonScan(scannedText)) {
      const season = scannedText.slice(scannedText.length - 5);
      return {
        type: 'season',
        seasonId: season
      };
    }
    if (this.isDeviceScan(scannedText)) {
      const deviceId = scannedText.slice(scannedText.length - 5);
      return {
        type: 'device',
        deviceId: deviceId
      };
    }
    if (this.isSmotScan(scannedText)) {
      const logicalId = scannedText.slice(scannedText.length - 5);
      return {
        type: 'smot',
        logicalId: logicalId
      };
    }
    return null;
  }

  public getTopLevelEntity(scannedQR: ScannedQR): Observable<{ entityType, value }> {
    switch (scannedQR.type) {
      case 'device':
        // Attempt to find a SMOTSpot
        return this.smotService.getSmotByDevice(+scannedQR.deviceId).pipe(
          filter(x => x !== undefined),
          switchMap((smot: any) => {
            if (!smot) {
              // No SMOTSpot found, attempt to find Device
              return this.deviceService.getDeviceById(+scannedQR.deviceId).pipe(
                filter(x => x !== undefined),
                map(device => ({
                  value: device,
                  entityType: 'device'
                }))
              );
            }
            return of({
              entityType: 'smot',
              value: smot
            });
          })
        );
      case 'casing':
      case 'season':
      case 'backplate':
      case 'custom-casing':
        // Attempt to find SMOTSpot or Device
        return this.smotService.getSmotFromCasingOrBackplateOrSeasonIdOrCustomCasingId(
          scannedQR.casingId,
          scannedQR.backplateId,
          scannedQR.seasonId,
          scannedQR.customCasingId).pipe(
          filter(x => x !== undefined),
          switchMap((entity: any) => {
            if (!entity) {
              // No Device or SMOTSpot found, try to find Custom Casing
              if (scannedQR.customCasingId) {
                return this.customCasingService.getCustomCasingById(+scannedQR.customCasingId).pipe(
                  filter(x => x !== undefined),
                  switchMap(customCasing => {
                    if (!customCasing) {
                      return this.casingService.getCasingById(+scannedQR.casingId).pipe(
                        filter(x => x !== undefined),
                        map(casing => ({
                          value: casing,
                          entityType: casing ? 'casing' : 'notFound'
                        }))
                      );
                    } else {
                      return of({
                        value: customCasing,
                        entityType: 'customCasing'
                      });
                    }
                  })
                );
              } else if (scannedQR.casingId || scannedQR.seasonId) {
                return this.customCasingService.getCustomCasingByCasingIdOrSeasonId(scannedQR.casingId ?? '0', scannedQR.seasonId ?? '0').pipe(
                  filter(x => x !== undefined),
                  switchMap(customCasing => {
                    if (!customCasing) {
                      return this.casingService.getCasingById(+scannedQR.casingId).pipe(
                        filter(x => x !== undefined),
                        map(casing => ({
                          value: casing,
                          entityType: casing ? 'casing' : 'notFound'
                        }))
                      );
                    } else {
                      return of({
                        value: customCasing,
                        entityType: 'customCasing'
                      });
                    }
                  })
                );
              } else if (scannedQR.backplateId) {
                return this.backplateService.getBackplate(+scannedQR.backplateId).pipe(
                  filter(x => x !== undefined),
                  map(backplate => ({
                    value: backplate,
                    entityType: backplate ? 'backplate' : 'notFound'
                  }))
                );
              }
            }

            // Check if entity is a device, if yes return device
            if (this.deviceService.instanceOfDevice(entity)) {
              return of({
                entityType: 'device',
                value: entity,
              });
            }

            if (this.smotService.instanceOfSmot(entity)) {
              // If no return smot
              return of({
                entityType: 'smot',
                value: entity
              });
            }
          })
        );
      case 'smot':
        return this.smotService.getSmot(+scannedQR.logicalId).pipe(
          filter(x => x !== undefined),
          map(smot => {
            if (smot === null) {
              return {
                entityType: 'notFound',
                value: null
              };
            }
            return {
              entityType: 'smot',
              value: smot
            };
          })
        );
      case 'sunscreen':
      default:
        return of({
          entityType: 'notFound',
          value: null
        });
    }
  }

  isSunscreenScan(scannedText) {
    return this.sunscreenRegexFormat.test(scannedText);
  }

  isBackplateScan(scannedText) {
    return this.backplateRegexFormat.test(scannedText);
  }

  isCasingScan(scannedText) {
    return this.casingRegexFormat.test(scannedText);
  }

  isCustomCasingScan(scannedText) {
    return this.customCasingRegexFormat.test(scannedText);
  }

  isSeasonScan(scannedText) {
    return this.seasonRegexFormat.test(scannedText);
  }

  isDeviceScan(scannedText) {
    return this.deviceIdRegexFormat.test(scannedText);
  }

  isSmotScan(scannedText) {
    return this.smotRegexFormat.test(scannedText);
  }
}
