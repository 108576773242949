import {Injectable} from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {Observable} from 'rxjs';
import {DeviceDTO} from '../dtos/deviceDTO';
import {environment} from '../../environments/environment';
import {Sort} from '@angular/material/sort';
import { BackplateDTO } from '../dtos/BackplateDTO';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private deviceUrl: string = environment.apiRoot + '/devices';

  constructor(private helper: ApiHelperService) {
  }

  getDeviceById(id: number): Observable<DeviceDTO> {
    const url = this.deviceUrl + '/' + id;
    return this.helper.getRequest<DeviceDTO>(url);
  }
  /**
   * Gets a device with the given backplate_id
   */
  getDeviceByBackplate(backplateId: string): Observable<DeviceDTO> {
    return this.helper.getRequest<DeviceDTO>(this.deviceUrl + '/bck/' + backplateId);
  }

  getDevices(page: number, size: number, search: string, sort: Sort) {
    let url = this.deviceUrl;
    url += this.helper.getPaginationString(page, size, search);
    url += this.helper.getSortString(sort);
    return this.helper.getRequest<DeviceDTO>(url, search);
  }


  /**
   * Fetch all devices not linked to a SMOTSpot
   */
  getAvailableDevices(page: number, size: number, search: string, sort: Sort, clientNumber: number, language: string, clientVariantNumber: number): Observable<DeviceDTO> {
    let url = this.deviceUrl;
    url += this.helper.getPaginationString(page, size, search);
    url += this.helper.getSortString(sort);
    url += '&fetchType=available';
    if (clientNumber) {
      url += '&clientNumber=' + clientNumber;
    }
    if (language) {
      url += '&language=' + language;
    }
    if (clientVariantNumber) {
      url += '&clientVariantNumber=' + clientVariantNumber;
    }
    return this.helper.getRequest<DeviceDTO>(url, search);
  }

  createDevice(device: DeviceDTO): Observable<DeviceDTO> {
    const createUrl = this.deviceUrl + '/create';
    return this.helper.postRequest<DeviceDTO>(createUrl, device);
  }

  getDeviceByCasing(casingId: string): Observable<DeviceDTO> {
    return this.helper.getRequest<DeviceDTO>(this.deviceUrl + '/cas/' + casingId);
  }

  getDeviceByCustomCasingId(casingId: string): Observable<DeviceDTO> {
    return this.helper.getRequest<DeviceDTO>(this.deviceUrl + '/custom-casing/' + casingId);
  }

  getDeviceByCasingOrBackplate(casingId: string, backplateId: string): Observable<DeviceDTO> {
    const url = `${this.deviceUrl}/find?casingId=${+casingId ?? 0}&backplateId=${+backplateId ?? 0}`;
    return this.helper.getRequest<DeviceDTO>(url);
  }

  saveDevice(device: DeviceDTO): Observable<DeviceDTO> {
    return this.helper.putRequest(this.deviceUrl, device);
  }

  getBackplateById(bckId: number): Observable<BackplateDTO> {
    return this.helper.getRequest<BackplateDTO>(this.deviceUrl + '/backplate/' + bckId);
  }

  instanceOfDevice(obj: any) {
    return 'backplate' in obj && 'backplate' in obj && 'casingId' in obj;
  }

  deleteDevice(deviceId: string) {
    return this.helper.deleteRequest(this.deviceUrl + `/${deviceId}`, null);
  }
}
