import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Optional, Output} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {IDropdownOption} from '../../../components/form-fields/drop-down-field/drop-down-field.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidationFormsService} from '../validation-forms/validation-forms.service';
import {catchError, filter, map, shareReplay, tap} from 'rxjs/operators';
import {Role} from '../../../models/role';
import {AuthenticationService} from '../../../services/authentication.service';
import {ClientMappings} from '../../../models/dtoMappings';
import {ClientService} from '../../../services/client.service';
import {languages} from '../../lng-picker/lng-picker.component';
import {of} from 'rxjs/internal/observable/of';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ClientVariantDTO} from '../../../dtos/ClientDTOs/ClientVariantDTO';
import {ClientVariantService} from '../../../services/client-variant.service';
import {InputModalComponent} from '../../plugins/input-modal/input-modal.component';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';

@Component({
  selector: 'app-create-smot-form',
  templateUrl: './create-smot-form.component.html',
  styleUrls: ['./create-smot-form.component.scss']
})
export class CreateSmotFormComponent implements OnInit {
  @Output() createdSmotSpot = new EventEmitter<SmotDTO>();
  @Input() smot: SmotDTO;

  clients$: Observable<IDropdownOption[]>;
  languages$: Observable<IDropdownOption[]>;
  clientVariants$: Observable<IDropdownOption[]>;
  smotFormGroup: FormGroup = null;

  // Form Controls
  smotName: FormControl = new FormControl(null, [
    Validators.required,
    Validators.pattern(this.validationService.formRules.nonEmpty)]);
  smotClient: FormControl = new FormControl(null, Validators.required);
  smotClientVariant: FormControl = new FormControl(null, Validators.required);
  smotLanguage: FormControl = new FormControl(null, Validators.required);
  smotStartup: FormControl = new FormControl('09:00', Validators.required);
  smotSleep: FormControl = new FormControl('20:00', Validators.required);
  smotEvent: FormControl = new FormControl(false);
  isOwnMaintenanceControl: FormControl = new FormControl(false);

  disableButtons: boolean = false;
  disableClient: boolean = false;
  disableVariantControl: boolean = true;
  displayClientControls: boolean = false;
  createVariantOption: IDropdownOption = {
    value: 'CREATE_NEW',
    label: '+ Create new variant'
  };

  constructor(private validationService: ValidationFormsService,
              private authService: AuthenticationService,
              private clientService: ClientService,
              private clientVariantService: ClientVariantService,
              private toastr: ToastrNotificationService,
              private matDialog: MatDialog,
              @Optional() private matDialogRef: MatDialogRef<CreateSmotFormComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data) {
      this.smot = data?.smot;
      this.disableClient = !!data?.disableClient;
  }

  ngOnInit(): void {
    this.checkForInitialValues();
    this.createForm();
    this.initLanguages();
  }

  public onSubmit() {
    this.smotFormGroup.markAllAsTouched();
    if (this.smotFormGroup.invalid) {
      return;
    }
    this.createSmot().subscribe();
  }

  private createSmot(): Observable<SmotDTO> {
    if (this.displayClientControls) {
      return this.clientService.getClient(this.smotClient.value).pipe(
        filter(x => x !== undefined),
        map(client => ({
          logicalId: this.smot?.logicalId,
          locationDescription: this.smotName.value,
          wakeUpTime: Number(this.smotStartup.value.toString().split(':')[0]),
          sleepTime: Number(this.smotSleep.value.toString().split(':')[0]),
          isEvent: +(!!this.smotEvent.value),
          isOwnMaintenance: +this.isOwnMaintenanceControl.value,
          language: this.smotLanguage.value,
          client: client,
          clientVariant: {
            clientVariantId: this.smotClientVariant.value
          },
          isActive: this.smot?.isActive
        }) as SmotDTO),
        tap(smot => {
          if (this.matDialogRef) {
            this.matDialogRef.close(smot);
          } else {
            this.createdSmotSpot.emit(smot);
          }
        })
      );
    } else {
      return this.authService.getLoggedInUser().pipe(
        filter(x => x !== undefined),
        map(user => ({
          locationDescription: this.smotName.value,
          wakeUpTime: Number(this.smotStartup.value.toString().split(':')[0]),
          sleepTime: Number(this.smotSleep.value.toString().split(':')[0]),
          isEvent: +this.smotEvent.value,
          language: this.smotLanguage.value,
          client: user.client,
          clientVariant: {
            clientVariantId: this.smotClientVariant.value
          },
          isOwnMaintenance: +this.isOwnMaintenanceControl.value,
          isActive: this.smot?.isActive
        }) as SmotDTO),
        tap(smot => {
          if (this.matDialogRef) {
            this.matDialogRef.close(smot);
          } else {
            this.createdSmotSpot.emit(smot);
          }
        })
      );
    }
  }


  public handleClientSelection(event) {
    this.smotClientVariant.setValue(0);
    this.clientVariants$ = this.fetchClientVariantsByClientNumber(event);
  }

  private fetchClientVariantsByClientNumber(clientNumber, selectedClientVariantId: number = null) {
    return this.clientVariantService.getClientVariantsByClientNumber(clientNumber).pipe(
      filter(x => x !== undefined),
      map((clientVariants: ClientVariantDTO[]) => clientVariants.map(variant => ({
        value: variant.clientVariantId,
        label: variant.clientVariantIdentifier,
        selected: selectedClientVariantId ? selectedClientVariantId === variant.clientVariantId : variant.clientVariantIdentifier === 'Default'
      }) as IDropdownOption)),
      map(options => {
        options.push(this.createVariantOption);
        return options;
      }),
      tap(options => {
        const defaultVariant = options.find(option => option.selected);
        this.smotClientVariant.setValue(defaultVariant?.value);
        this.disableVariantControl = false;
      }),
    );
  }

  public handleClientVariantSelection(event) {
    if (event === this.createVariantOption.value) {
      this.smotClientVariant.setValue(0);
      const dialogRef = this.matDialog.open(InputModalComponent, {
        data: {
          key: 'translate.create.addClientVariant',
        }
      });

      dialogRef.afterClosed().pipe(
        tap(value => {
          this.createNewClientVariant(this.smotClient.value, value);
        }),
      ).subscribe();
    }
  }

  private createForm() {
    this.smotFormGroup = new FormGroup({
      name: this.smotName,
      language: this.smotLanguage,
      startupTime: this.smotStartup,
      sleepTime: this.smotSleep,
      event: this.smotEvent,
      isOwnMaintenance: this.isOwnMaintenanceControl,
    });

    // Check if user is partner, admin or staff for partner or admin and add Client Control
    this.authService.getLoggedInUser().pipe(
      filter(x => x !== undefined),
      tap(user => {
        const roles = [Role.STAFF, Role.ADMIN, Role.PARTNER];
        if (roles.includes(<Role>this.authService.getRole())) {
          if (user.client === null) {
            this.displayClientControls = true;
            this.smotFormGroup.addControl('client', this.smotClient);
            this.smotFormGroup.addControl('clientVariant', this.smotClientVariant);
            this.initClients();
          }
        }
      })
    ).subscribe();
  }

  private initClients() {
    this.clients$ = this.clientService.getClients(ClientMappings.DROPDOWN_CLIENT_DTO).pipe(
      filter(x => x !== undefined),
      map(clients => clients.map(client =>  ({
        label: client.clientName,
        value: client.clientNumber.toString()
      }))),
    );

    this.clients$.subscribe();
  }

  private initLanguages() {
    const tempLngs: IDropdownOption[] = [];
    languages.forEach(l => {
      tempLngs.push({
        label: l.language,
        value: l.fileName
      });
    });
    this.languages$ = of(tempLngs);
  }

  private checkForInitialValues() {
    if (this.smot) {
      this.smotName.setValue(this.smot.locationDescription);
      this.smotLanguage.setValue(this.smot.language);
      this.smotStartup.setValue((this.smot.wakeUpTime?.toString() ?? '9' ) + ':00');
      this.smotSleep.setValue((this.smot.sleepTime?.toString() ?? '20') + ':00');
      this.smotClient.setValue(this.smot.client.clientNumber.toString());
      this.smotEvent.setValue(this.smot.isEvent);
      this.isOwnMaintenanceControl.setValue(this.smot.isOwnMaintenance);

      if (this.smot.clientVariant) {
        this.clientVariants$ = this.fetchClientVariantsByClientNumber(this.smot.client.clientNumber, this.smot.clientVariant.clientVariantId);
      }
    }
  }

  private createNewClientVariant(clientNumber: number, variantName: string) {
    const clientVariant = {
      clientNumber: clientNumber,
      clientVariantIdentifier: variantName
    } as ClientVariantDTO;

    this.clientVariantService.createClientVariant(clientVariant).pipe(
      filter(x => x !== undefined),
      tap((createdVariant) => {
        this.clientVariants$ = this.fetchClientVariantsByClientNumber(clientNumber, createdVariant.clientVariantId);
        this.toastr.showSucces('Successfully created a new client variant', 'Succes');
      }),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    ).subscribe();
  }
}
