import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {SmotService} from '../../../services/smot.service';
import {DeviceService} from '../../../services/device.service';
import {combineLatest, EMPTY, Observable, of} from 'rxjs';
import {FormControl} from '@angular/forms';
import {catchError, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {ScannedQR} from '../../../models/ScannedQR';
import {ScannerService} from '../../../services/scanner.service';
import {DeviceDTO} from '../../../dtos/deviceDTO';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmActionModalComponent} from '../../plugins/confirm-action-modal/confirm-action-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {BaseFlowStepComponent} from '../../shared/flow/flow-steps/base-flow-step.interface';
import {CustomCasingService} from '../../../services/custom-casing.service';
import {CreateOperationDTO} from '../../../dtos/OperationDTOs/createOperationDTO';
import {OperationService} from '../../../services/operation.service';

@Component({
  selector: 'app-create-smot-spot',
  templateUrl: './create-smot-spot.component.html',
  styleUrls: ['./create-smot-spot.component.scss']
})
export class CreateSmotSpotComponent implements OnInit, BaseFlowStepComponent {
  @Input() data: any;
  @Output() nextStep: EventEmitter<true> = new EventEmitter();
  @Output() previousStep: EventEmitter<false> = new EventEmitter();
  currentStep: 'configs' | 'createConfig' | 'device' | 'checkInfoPanel' | 'overview' | 'refill' | 'finish' = 'configs';
  configurations$: Observable<SmotDTO[]>;
  filteredConfigurations$: Observable<SmotDTO[]>;
  searchControl = new FormControl();
  smot: SmotDTO;
  initialSmot: SmotDTO = null;
  device: DeviceDTO;
  badScan: boolean = false;
  deviceId: number;

  constructor(private smotService: SmotService,
              private scanService: ScannerService,
              public router: Router,
              private toastr: ToastrNotificationService,
              private matDialog: MatDialog,
              private deviceService: DeviceService,
              private customCasingService: CustomCasingService,
              private operationService: OperationService,
              public route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.deviceId = +params['deviceId'];
    });
  }

  ngOnInit(): void {
    this.fetchConfigurations();
    this.listenToSearchControlChanges();
  }

  public handleDeviceCodeScanned(code) {
    this.badScan = false;
    const scannedQR: ScannedQR = this.scanService.verifyScan(code);
    if (!scannedQR) {
      this.badScan = true;
      return;
    }
    this.scanService.getTopLevelEntity(scannedQR).pipe(
      filter(x => x !== undefined),
      switchMap(entity => {
        if (entity.entityType === 'device') {
          const device = entity.value as DeviceDTO;
          return this.checkForMissingDeviceComponents(device).pipe(
            filter(x => x !== undefined),
            tap(() => {
              this.device = device;
              this.smot.device = device;
              return this.checkCompatibility(of(device));
            })
          );
        } else if (entity.entityType === 'smot') {
          this.device = entity.value.device;
          this.smot.device = entity.value.device;
          return this.promptUserToReplaceDevice(entity.value);
        } else {
          this.toastr.showError('Scanned code is not a device', 'Error');
          return EMPTY;
        }
      }),
      filter(x => x),
      tap(() => {
        if (!this.device?.customCasing?.hasInfoPanel) {
          this.currentStep = 'checkInfoPanel';
        } else {
          this.currentStep = 'overview';
        }
      })
    ).subscribe();
  }

  private warnUserAboutMissingDeviceComponent(component: 'backplate' | 'customCasing' | 'casing', device: DeviceDTO) {
    const translateKeyMap = new Map([
      ['backplate', 'translate.production.create-smot.missingBackplate'],
      ['customCasing', 'translate.production.create-smot.missingCustomCasing'],
      ['casing', 'translate.production.create-smot.missingCasing'],
    ]);

    const dialogRef = this.matDialog.open(ConfirmActionModalComponent, {
      data: {
        key: translateKeyMap.get(component),
      }
    });

    return dialogRef.afterClosed().pipe(
      filter(x => !!x),
      tap(() => {
        this.device = device;
        this.smot.device = device;
        return this.checkCompatibility(of(device));
      })
    );
  }

  public saveSmot() {
    this.smot.isActive = 2; // Smot is inactive
    const info = {
      newDeviceId: this.device.deviceId,
      repairPart: 'Device'
    };
    const operation: CreateOperationDTO = {
      operationType: 'REPAIR',
      smotLogicalId: this.smot.logicalId,
      info: JSON.stringify(info),
    };

    this.operationService.postOperation(operation).pipe(
      filter(x => x !== undefined),
      switchMap(() => this.smotService.saveSmot(this.smot).pipe(
        filter(x => x !== undefined),
        tap((smot) => {
          this.toastr.showSucces('SMOTspot werd succesvol aangemaakt', 'Success');
          this.router.navigate([`production/create/smotspot`], {
            queryParams: {
              smotId: this.smot.logicalId,
            }
          });
          this.nextStep.emit(true);
        })
      ))
    ).subscribe();
  }

  public goToDeviceStep() {
    if (this.deviceId) {
      this.checkDeviceAvailability(this.device).subscribe();
    } else {
      this.currentStep = 'device';
    }
  }

  public goToCreateConfigStep() {
    this.currentStep = 'createConfig';
  }

  public addInfoPanelToCustomCasing() {
    this.device.customCasing.hasInfoPanel = true;
    this.customCasingService.updateCustomCasing(this.device.customCasing).pipe(
      filter(x => x !== undefined),
      tap(() => {
        this.toastr.showSucces('Info bord toegevoegd', 'Success');
        this.currentStep = 'overview';
      })
    ).subscribe();
  }

  private fetchConfigurations() {
    this.configurations$ = this.smotService.getConfigurations<SmotDTO[]>().pipe(
      filter(x => x !== undefined),
      switchMap(configurations => {
        if (this.deviceId) {
          return this.deviceService.getDeviceById(this.deviceId).pipe(
            filter(x => x !== undefined),
            map(device => {
              this.device = device;
              if (this.smot) {
                this.smot.device = device;
              }
              this.initialSmot = {
                clientVariant: {
                  clientVariantId: device.customCasing.clientVariantId,
                },
                client: {
                  clientNumber: device.customCasing.clientNumber
                },
                language: device.customCasing.language
              } as SmotDTO;
              return configurations
                .filter(config => config.client.clientNumber === device.customCasing.clientNumber
                  && config.clientVariant.clientVariantId === device.customCasing.clientVariantId);
            })
          );
        }
        return of(configurations);
      })
    );
  }

  private checkCompatibility(device$: Observable<DeviceDTO>) {
    return device$.pipe(
      tap(device => {
        if (!this.smotService.checkCompatibilityBetweenConfigAndDevice(device, this.smot)) {
          this.currentStep = 'device';
          throw new Error('Not compatible');
        }
      }),
      catchError(() => {
        this.toastr.showError('De configuraties komen niet overeen', 'Error');
        return EMPTY;
      })
    );
  }

  private checkDeviceAvailability(device: DeviceDTO) {
    return this.smotService.getSmotByDevice(device.deviceId).pipe(
      filter(x => x !== undefined),
      switchMap(smot => {
        if (smot) {
          return this.promptUserToReplaceDevice(smot);
        } else {
          if (!this.device?.customCasing?.hasInfoPanel) {
            this.currentStep = 'checkInfoPanel';
          } else {
            this.currentStep = 'overview';
          }
        }
        return of(device);
      })
    );
  }

  private promptUserToReplaceDevice(smot: SmotDTO) {
    const dialogRef = this.matDialog.open(ConfirmActionModalComponent, {
      data: {
        key: 'Een SMOTSpot met dit toestel bestaat al, wil je dit toestel toch gebruiken?',
        html: '<ul>' +
          `<li>Logical ID: ${smot.logicalId}</li>` +
          `<li>Locatie Beschrijving: ${smot.locationDescription}</li>` +
          `<li>Klant: ${smot.client.clientName}</li>` +
          `<li>Variant: ${smot.clientVariant.clientVariantIdentifier}</li>` +
          `<li>Taal: ${smot.language}</li>` +
          '</ul>'
      }
    });

    return dialogRef.afterClosed().pipe(
      filter(x => x !== undefined),
      switchMap(response => {
        if (response) {
          return this.checkForMissingDeviceComponents(smot.device);
        } else {
          throw new Error('Not available');
        }
      }),
      catchError(() => {
        this.toastr.showError('Dit toestel wordt al gebruikt in een andere SMOTSpot', 'Error');
        return EMPTY;
      })
    );
  }

  private listenToSearchControlChanges() {
    this.filteredConfigurations$ = combineLatest([this.searchControl.valueChanges.pipe(
      startWith('')
    ), this.configurations$]).pipe(
      map(([searchString, configurations]) => {
        this.smot = null;
        return configurations.filter(configuration => this.checkIfSearchStringMatchesSmotProperties(searchString, configuration));
      })
    );
  }

  private checkIfSearchStringMatchesSmotProperties(searchString: string, smot: SmotDTO): boolean {
    return smot.locationDescription?.includes(searchString)
      || smot.client?.clientName?.includes(searchString)
      || smot.language?.includes(searchString)
      || smot.clientVariant?.clientVariantIdentifier?.includes(searchString);
  }

  private checkForMissingDeviceComponents(device: DeviceDTO) {
    // Warn the user in case one of the components is missing
    if (!device.backplate) {
      return this.warnUserAboutMissingDeviceComponent('backplate', device);
    }

    if (!device.customCasing) {
      return this.warnUserAboutMissingDeviceComponent('customCasing', device);
    }

    if (!device.customCasing.casingId) {
      return this.warnUserAboutMissingDeviceComponent('casing', device);
    }

    return this.checkCompatibility(of(device));
  }
}
